.hero-section{
  display: flex;
  width: 80%;
  padding-bottom: 1.5rem;
  margin: auto;
}
.hero-left-child{
  width: 60%;
}

.hero-left-child .title{
  font-size: 25px;
  font-weight: 500;
  color: #086ED4;
  margin-top: 1rem;

}

.hero-left-child .subtitle{
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 2rem;
}


.cousre-section{
  width: 80%;
  margin: auto;
  padding: 3rem 1rem;
  background-color: #fff;
}

.cousre-section .title{
  font-weight: 700;
  font-size: 50px
}

.cousre-section .content{
  display: flex;
}

.cousre-section .content .paper1{
  padding: 2rem;
  background-color: #ECF4F8;
  margin-right: 15px;
}

.cousre-section .content .paper2{
  padding: 2rem;
  background-color: #ECF4F8;
  margin-left: 15px;
}

.individuals-box{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}


.hero-right-child{
  width: 50%;
  margin-top: 10rem;
  margin-left: 3rem;
}

.hero-right-child img{
  width: 90%;
  /* height: 20%; */
}

.faculty-section {
  width: 80%;
  padding: 3rem 1rem;
  margin: auto;
  display: flex;
  background-color: #fff;
  
}

.faculty-right-child {
  width: 50%;
  padding-left: 2rem;
}

.faculty-right-child .title {
  font-weight: 700;
  font-size: 50px;

}

.faculty-left-child {
  width: 50%;
}

.testimonial-section{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.testimonial-section .title{
  font-weight: 700;
  font-size: 50px;
  padding-bottom: 2rem;
}

.testimonial-section .content{
  display: flex;
}

.testimonial-section .content .content-card1, .content-card2, .content-card3 {
  display: flex;
  justify-content: center;
  padding: 2rem;
  margin-right: 1rem;
  background-color: #ECF4F8;
  margin-bottom: 1rem;
  width: 100%;
}



.footer-main {
  background-color: #2B363B;
}

.footer-section {
  width: 50%;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.footer-service-button {
  background-color: #005AC0;
  font-size: 20px;
  font-weight: 500;
  border: none;
  color: white;
  padding: .7rem 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.footer-service-button:hover {
  background-color: #00438f;
}

@media screen and (max-width: 600px) {

  .hero-section{
    width: 100%;
  }

  .cousre-section{
    width: 100%;
    padding: 3rem 2rem;
  }

  .faculty-section {
    flex-direction: column;
    width: 100%;
  }

  .faculty-right-child {
    width: 100%;
    padding-right: 1rem;
    margin-left: 1rem;

  }

  .faculty-left-child{
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .testimonial-section{
    width: 100%;
  }

  .footer-section {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {

  .cousre-section .title{
    font-weight: 600;
    font-size: 30px;
  }
  .cousre-section .content{
    flex-direction: column;
  }

  .cousre-section .content .paper1{
    margin-right: 0px;
    margin-bottom: 1rem;
  }
  
  .cousre-section .content .paper2{
    margin-left: 0px;
  }

  .faculty-section {
    flex-direction: column;
    padding: 3rem 1rem;
  }

  .faculty-right-child {
    width: 100%;
    padding-left: 0rem;
  }


  .faculty-right-child .title {
    font-weight: 600;
    font-size: 30px;
    margin-top: 2rem;
  }

  .testimonial-section .title{
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 1rem;
    
  }


}

@media screen and (max-width: 900px) {

  .testimonial-section .content{
    flex-direction: column;
  }

  .testimonial-section .content .content-card1{
    margin-right: 0;
  }
  

}

@media screen and (max-width: 1000px) {

  
  .hero-right-child{
    margin-left: 0;
    width: 100%;
    margin-top: 2rem;
  }

  .hero-right-child img{
    width: 100%;
  }

  
  .hero-section{
    flex-direction: column;
  }
  .hero-left-child{
    width: 100%;
  }

  .hero-left-child .title{
    font-size: 20px;
  
  }

  .hero-left-child .subtitle{
    font-size: 40px;
    font-weight: 700;
  }


}